export const rfidIcon = (color: string) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 6C2 4.89543 2.89543 4 4 4H5H19H20C21.1046 4 22 4.89543 22 6V7V17V18C22 19.1046 21.1046 20 20 20H19H5H4C2.89543 20 2 19.1046 2 18V17V7V6ZM19.0002 6C19.5524 6.00013 20 6.4478 20 7V17C20 17.5523 19.5523 18 19 18H5C4.44772 18 4 17.5523 4 17V7C4 6.44803 4.4472 6.00052 4.99904 6L5 6H19H19.0002ZM15.7033 16.6621C15.5333 17.0621 15.0633 17.2421 14.6833 17.0521C14.3333 16.8821 14.1633 16.4621 14.3133 16.1021C14.9033 14.7121 15.2033 13.3521 15.2033 12.0421C15.2033 10.7321 14.9033 9.39214 14.3233 8.03214C14.1633 7.67214 14.3333 7.25214 14.6833 7.08214C15.0733 6.88214 15.5333 7.06214 15.7033 7.46214C16.3633 9.00214 16.7033 10.5421 16.7033 12.0421C16.7033 13.5421 16.3633 15.1021 15.7033 16.6621ZM11.5333 15.6821C11.9233 15.9021 12.4233 15.7421 12.5933 15.3321C13.0033 14.3721 13.2133 13.3121 13.1933 12.1821C13.1933 11.0321 12.9833 9.91214 12.5633 8.85214C12.4033 8.44214 11.9233 8.26214 11.5333 8.46214C11.1933 8.64214 11.0333 9.04214 11.1733 9.40214C11.5233 10.2921 11.7033 11.2221 11.7033 12.1821C11.7033 13.1121 11.5433 13.9721 11.2133 14.7421C11.0733 15.0921 11.2033 15.4921 11.5333 15.6821ZM8.4333 14.1921C8.8333 14.3721 9.3233 14.1721 9.4533 13.7421C9.6233 13.1721 9.7033 12.5921 9.7033 12.0321C9.6933 11.4921 9.6233 10.9721 9.4733 10.4621C9.3433 10.0121 8.8533 9.78214 8.4333 9.97214C8.0933 10.1221 7.9333 10.5121 8.0333 10.8721C8.1433 11.2521 8.1933 11.6421 8.2033 12.0521C8.2133 12.4621 8.1533 12.8821 8.0333 13.2921C7.9233 13.6521 8.0933 14.0321 8.4333 14.1921Z'
    />
  </svg>
);
export const notificationIcon = (color: string) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_3204_11509)'>
      <path
        d='M12.0001 22C13.1001 22 14.0001 21.1 14.0001 20H10.0001C10.0001 21.1 10.8901 22 12.0001 22ZM18.0001 16V11C18.0001 7.93 16.3601 5.36 13.5001 4.68V4C13.5001 3.17 12.8301 2.5 12.0001 2.5C11.1701 2.5 10.5001 3.17 10.5001 4V4.68C7.63005 5.36 6.00005 7.92 6.00005 11V16L4.71005 17.29C4.08005 17.92 4.52005 19 5.41005 19H18.5801C19.4701 19 19.9201 17.92 19.2901 17.29L18.0001 16Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_3204_11509'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const infoIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_495_8037)'>
      <path
        d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 17C11.45 17 11 16.55 11 16V12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12V16C13 16.55 12.55 17 12 17ZM13 9H11V7H13V9Z'
        fill='#18A0D7'
      />
    </g>
    <defs>
      <clipPath id='clip0_495_8037'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const translateIcon = (color: string) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.7125 13.0583C10.8292 12.7583 10.7542 12.4166 10.5208 12.1833L8.77915 10.4666L8.80415 10.4416C10.2541 8.82496 11.2875 6.96663 11.8958 4.99996H13.5125C13.9625 4.99996 14.3375 4.62496 14.3375 4.17496V4.15829C14.3375 3.70829 13.9625 3.33329 13.5125 3.33329H8.50415V2.49996C8.50415 2.04163 8.12915 1.66663 7.67082 1.66663C7.21248 1.66663 6.83748 2.04163 6.83748 2.49996V3.33329H1.82915C1.37915 3.33329 1.00415 3.70829 1.00415 4.15829C1.00415 4.61663 1.37915 4.98329 1.82915 4.98329H10.3125C9.75415 6.59996 8.87082 8.12496 7.67082 9.45829C6.99582 8.71663 6.42915 7.90829 5.95415 7.05829C5.82082 6.81663 5.57915 6.66663 5.30415 6.66663C4.72915 6.66663 4.36248 7.29163 4.64582 7.79163C5.17082 8.73329 5.81248 9.63329 6.56248 10.4666L2.92082 14.0583C2.58748 14.3833 2.58748 14.9166 2.92082 15.2416C3.24582 15.5666 3.77082 15.5666 4.10415 15.2416L7.67082 11.6666L9.35415 13.35C9.77915 13.775 10.5041 13.6166 10.7125 13.0583ZM14.7543 8.33333C14.2543 8.33333 13.8043 8.64167 13.6293 9.11667L10.571 17.2833C10.371 17.7917 10.7543 18.3333 11.296 18.3333C11.621 18.3333 11.9126 18.1333 12.0293 17.825L12.771 15.8333H16.7293L17.4793 17.825C17.596 18.125 17.8876 18.3333 18.2126 18.3333C18.7543 18.3333 19.1376 17.7917 18.946 17.2833L15.8876 9.11667C15.7043 8.64167 15.2543 8.33333 14.7543 8.33333ZM14.7541 10.5584L13.4041 14.1667H16.1041L14.7541 10.5584Z'
    />
  </svg>
);

export const paymentIcon = (color: string) => (
  <svg
    width='21'
    height='16'
    viewBox='0 0 21 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM17 14H3C2.45 14 2 13.55 2 13V8H18V13C18 13.55 17.55 14 17 14ZM18 4H2V2H18V4Z'
      fill={color}
    />
  </svg>
);

export const helpIcon = (color: string) => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_495_8285)'>
      <path
        d='M19.5 2H5.5C4.39 2 3.5 2.9 3.5 4V18C3.5 19.1 4.4 20 5.5 20H9.5L11.79 22.29C12.18 22.68 12.81 22.68 13.2 22.29L15.5 20H19.5C20.6 20 21.5 19.1 21.5 18V4C21.5 2.9 20.6 2 19.5 2ZM13.5 18H11.5V16H13.5V18ZM15.57 10.25L14.67 11.17C14.09 11.76 13.68 12.27 13.55 13.23C13.49 13.66 13.14 13.99 12.7 13.99H12.39C11.87 13.99 11.47 13.53 11.54 13.01C11.65 12.1 12.07 11.29 12.68 10.67L13.92 9.41C14.28 9.05 14.5 8.55 14.5 8C14.5 6.9 13.6 6 12.5 6C11.63 6 10.88 6.57 10.61 7.35C10.48 7.72 10.17 7.99 9.78 7.99H9.48C8.9 7.99 8.5 7.43 8.66 6.87C9.15 5.21 10.68 4 12.5 4C14.71 4 16.5 5.79 16.5 8C16.5 8.88 16.14 9.68 15.57 10.25Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_495_8285'>
        <rect width='24' height='24' fill='white' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </svg>
);

export const termsIcon = (color: string) => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_495_8289)'>
      <path
        d='M12.5 2C6.98 2 2.5 6.48 2.5 12C2.5 17.52 6.98 22 12.5 22C18.02 22 22.5 17.52 22.5 12C22.5 6.48 18.02 2 12.5 2ZM12.5 17C11.95 17 11.5 16.55 11.5 16V12C11.5 11.45 11.95 11 12.5 11C13.05 11 13.5 11.45 13.5 12V16C13.5 16.55 13.05 17 12.5 17ZM13.5 9H11.5V7H13.5V9Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_495_8289'>
        <rect width='24' height='24' fill='white' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </svg>
);

export const forwardIcon = () => (
  <svg
    width='12'
    height='20'
    viewBox='0 0 12 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill='#B0B8C1'
      d='M0.87999 19.0108C1.36999 19.5008 2.15999 19.5008 2.64999 19.0108L10.96 10.7008C11.35 10.3108 11.35 9.68078 10.96 9.29078L2.64999 0.980781C2.15999 0.490781 1.36999 0.490781 0.87999 0.980781C0.38999 1.47078 0.38999 2.26078 0.87999 2.75078L8.11999 10.0008L0.86999 17.2508C0.38999 17.7308 0.38999 18.5308 0.87999 19.0108Z'
    />
  </svg>
);

export const manageAccountIcon = () => (
  <svg
    width='24'
    height='26'
    viewBox='0 0 28 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.296 12.2941C14.4153 12.2941 16.944 9.76536 16.944 6.64606C16.944 3.52675 14.4153 0.998047 11.296 0.998047C8.17665 0.998047 5.64795 3.52675 5.64795 6.64606C5.64795 9.76536 8.17665 12.2941 11.296 12.2941Z'
      fill='#4E5968'
    />
    <path
      d='M12.2421 13.7353C11.9314 13.7212 11.6208 13.707 11.296 13.707C7.87897 13.707 4.68785 14.6531 1.96268 16.2769C0.720121 17.0111 0 18.3949 0 19.8492V23.591H13.0751C11.9597 21.9955 11.296 20.0469 11.296 17.943C11.296 16.4322 11.649 15.0202 12.2421 13.7353Z'
      fill='#4E5968'
    />
    <path
      d='M26.4751 17.9428C26.4751 17.6322 26.4327 17.3498 26.3904 17.0533L28 15.6271L26.588 13.1844L24.5406 13.8763C24.0888 13.495 23.5805 13.1985 23.0157 12.9867L22.5921 10.8828H19.7681L19.3445 12.9867C18.7797 13.1985 18.2714 13.495 17.8195 13.8763L15.7721 13.1844L14.3601 15.6271L15.9698 17.0533C15.9274 17.3498 15.8851 17.6322 15.8851 17.9428C15.8851 18.2535 15.9274 18.5359 15.9698 18.8324L14.3601 20.2585L15.7721 22.7013L17.8195 22.0094C18.2714 22.3906 18.7797 22.6872 19.3445 22.899L19.7681 25.0028H22.5921L23.0157 22.899C23.5805 22.6872 24.0888 22.3906 24.5406 22.0094L26.588 22.7013L28 20.2585L26.3904 18.8324C26.4327 18.5359 26.4751 18.2535 26.4751 17.9428ZM21.1801 20.7668C19.6269 20.7668 18.3561 19.496 18.3561 17.9428C18.3561 16.3896 19.6269 15.1188 21.1801 15.1188C22.7333 15.1188 24.0041 16.3896 24.0041 17.9428C24.0041 19.496 22.7333 20.7668 21.1801 20.7668Z'
      fill='#4E5968'
    />
  </svg>
);

export const sessionDurationIcon = (color: string) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_1089_6238)'>
      <path
        d='M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12.5 7H11V13L16.2 16.2L17 14.9L12.5 12.2V7Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_1089_6238'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const sessionEnergyIcon = (color: string) => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_1089_6244)'>
      <path
        d='M17.5721 9.1734C17.5229 9.06779 17.417 9.00001 17.3003 9.00001H13.7453L17.2538 3.46051C17.3123 3.36812 17.3159 3.25112 17.2631 3.15543C17.2103 3.05941 17.1095 3 17.0003 3H12.2003C12.0866 3 11.9828 3.0642 11.9318 3.1659L7.43178 12.1659C7.38527 12.2586 7.39037 12.369 7.44496 12.4575C7.49988 12.546 7.59617 12.6 7.70027 12.6H10.7852L7.42366 20.5836C7.36635 20.7201 7.41737 20.8785 7.54365 20.9559C7.59223 20.9856 7.64623 21 7.69995 21C7.78605 21 7.87095 20.9631 7.92977 20.8935L17.5298 9.49346C17.6051 9.4041 17.6213 9.27929 17.5721 9.1734Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_1089_6244'>
        <rect width='24' height='24' fill='white' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </svg>
);

export const rfidVector = (color: string) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_495_9099)'>
        <path
          d='M17.9998 3.03027C9.73478 3.03027 3.02979 9.73527 3.02979 18.0003C3.02979 26.2653 9.73478 32.9703 17.9998 32.9703C26.2648 32.9703 32.9698 26.2653 32.9698 18.0003C32.9698 9.73527 26.2648 3.03027 17.9998 3.03027ZM17.2198 26.8203V20.6103H13.2298C12.6748 20.6103 12.2998 20.0103 12.5698 19.5153L18.0898 8.76027C18.4348 8.05527 19.4998 8.31027 19.4998 9.10527V15.3903H23.3098C23.8648 15.3903 24.2248 15.9753 23.9848 16.4703L18.6448 27.1503C18.2848 27.8703 17.2198 27.6153 17.2198 26.8203Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_495_9099'>
          <rect width='36' height='36' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const rfidCart = (color: string) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='18' cy='18' r='15' fill={color} />
      <path
        d='M23.8333 11.9997H22.1667C22.1667 9.69967 20.3 7.83301 18 7.83301C15.7 7.83301 13.8333 9.69967 13.8333 11.9997H12.1667C11.25 11.9997 10.5 12.7497 10.5 13.6663V23.6663C10.5 24.583 11.25 25.333 12.1667 25.333H23.8333C24.75 25.333 25.5 24.583 25.5 23.6663V13.6663C25.5 12.7497 24.75 11.9997 23.8333 11.9997ZM18 9.49967C19.3833 9.49967 20.5 10.6163 20.5 11.9997H15.5C15.5 10.6163 16.6167 9.49967 18 9.49967ZM18 17.833C16.0583 17.833 14.425 16.508 13.9667 14.708C13.825 14.183 14.2333 13.6663 14.775 13.6663C15.1667 13.6663 15.4833 13.9497 15.5917 14.333C15.8833 15.3913 16.85 16.1663 18 16.1663C19.15 16.1663 20.1167 15.3913 20.4083 14.333C20.5167 13.9497 20.8333 13.6663 21.225 13.6663C21.7667 13.6663 22.1667 14.183 22.0333 14.708C21.575 16.508 19.9417 17.833 18 17.833Z'
        fill='white'
      />
    </svg>
  );
};

export const rfidHouse = (color: string) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='18' cy='18' r='15' fill={color} />
      <g clip-path='url(#clip0_495_9109)'>
        <path
          d='M19.8002 15.6664L15.6335 12.6914C15.0502 12.2747 14.2752 12.2747 13.7002 12.6914L9.5335 15.6664C9.09183 15.9831 8.8335 16.4831 8.8335 17.0247V23.6664C8.8335 24.1247 9.2085 24.4997 9.66683 24.4997H13.0002V19.4997H16.3335V24.4997H19.6668C20.1252 24.4997 20.5002 24.1247 20.5002 23.6664V17.0247C20.5002 16.4831 20.2418 15.9831 19.8002 15.6664Z'
          fill='white'
        />
        <path
          d='M25.5252 9.5H17.9752C17.0668 9.5 16.3335 10.2333 16.3335 11.1417L16.4085 11.2167C16.4752 11.2583 16.5418 11.2917 16.6085 11.3333L20.7752 14.3083C21.4085 14.7583 21.8585 15.425 22.0585 16.1667H23.8335V17.8333H22.1668V19.5H23.8335V21.1667H22.1668V23.6667V24.5H25.5252C26.4335 24.5 27.1668 23.7667 27.1668 22.8583V11.1417C27.1668 10.2333 26.4335 9.5 25.5252 9.5ZM23.8335 14.5H22.1668V12.8333H23.8335V14.5Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_495_9109'>
          <rect
            width='20'
            height='20'
            fill='white'
            transform='translate(8 7)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const sessionMoneyIcon = (color: string) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_1089_1486)'>
      <path
        d='M11.8 10.9C9.53 10.31 8.8 9.7 8.8 8.75C8.8 7.66 9.81 6.9 11.5 6.9C12.92 6.9 13.63 7.44 13.89 8.3C14.01 8.7 14.34 9 14.76 9H15.06C15.72 9 16.19 8.35 15.96 7.73C15.54 6.55 14.56 5.57 13 5.19V4.5C13 3.67 12.33 3 11.5 3C10.67 3 10 3.67 10 4.5V5.16C8.06 5.58 6.5 6.84 6.5 8.77C6.5 11.08 8.41 12.23 11.2 12.9C13.7 13.5 14.2 14.38 14.2 15.31C14.2 16 13.71 17.1 11.5 17.1C9.85 17.1 9 16.51 8.67 15.67C8.52 15.28 8.18 15 7.77 15H7.49C6.82 15 6.35 15.68 6.6 16.3C7.17 17.69 8.5 18.51 10 18.83V19.5C10 20.33 10.67 21 11.5 21C12.33 21 13 20.33 13 19.5V18.85C14.95 18.48 16.5 17.35 16.5 15.3C16.5 12.46 14.07 11.49 11.8 10.9Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_1089_1486'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const arrowBack = (color: string) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_1443_10042)'>
      <path
        d='M16.6201 2.99006C16.1301 2.50006 15.3401 2.50006 14.8501 2.99006L6.54006 11.3001C6.15006 11.6901 6.15006 12.3201 6.54006 12.7101L14.8501 21.0201C15.3401 21.5101 16.1301 21.5101 16.6201 21.0201C17.1101 20.5301 17.1101 19.7401 16.6201 19.2501L9.38006 12.0001L16.6301 4.75006C17.1101 4.27006 17.1101 3.47006 16.6201 2.99006Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_1443_10042'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const deleteIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_533_9390)'>
      <path
        d='M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM18 4H15.5L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4Z'
        fill='#4E5968'
      />
    </g>
    <defs>
      <clipPath id='clip0_533_9390'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const checkboxCheckedIcon = (color: string) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM3.7 10.7L7.29 14.29C7.68 14.68 8.32 14.68 8.7 14.29L16.29 6.7C16.68 6.31 16.68 5.68 16.29 5.29C15.9 4.9 15.27 4.9 14.88 5.29L8 12.17L5.11 9.29C4.72 8.9 4.09 8.9 3.7 9.29C3.51275 9.47683 3.40751 9.73048 3.40751 9.995C3.40751 10.2595 3.51275 10.5132 3.7 10.7Z'
      fill={color}
    />
  </svg>
);

export const checkboxUncheckedIcon = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='10' cy='10' r='9' stroke='#E5E8EB' stroke-width='2' />
  </svg>
);

export const personalInfoIcon = (color: string) => (
  <svg
    width='21'
    height='20'
    viewBox='0 0 21 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM10.5 3C12.16 3 13.5 4.34 13.5 6C13.5 7.66 12.16 9 10.5 9C8.84 9 7.5 7.66 7.5 6C7.5 4.34 8.84 3 10.5 3ZM10.5 17.2C8 17.2 5.79 15.92 4.5 13.98C4.53 11.99 8.5 10.9 10.5 10.9C12.49 10.9 16.47 11.99 16.5 13.98C15.21 15.92 13 17.2 10.5 17.2Z'
      fill={color}
    />
  </svg>
);

export const errorInfoIcon = () => (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.99984 0.666992C4.39984 0.666992 0.666504 4.40033 0.666504 9.00033C0.666504 13.6003 4.39984 17.3337 8.99984 17.3337C13.5998 17.3337 17.3332 13.6003 17.3332 9.00033C17.3332 4.40033 13.5998 0.666992 8.99984 0.666992ZM8.99984 9.83366C8.5415 9.83366 8.1665 9.45866 8.1665 9.00033V5.66699C8.1665 5.20866 8.5415 4.83366 8.99984 4.83366C9.45817 4.83366 9.83317 5.20866 9.83317 5.66699V9.00033C9.83317 9.45866 9.45817 9.83366 8.99984 9.83366ZM9.83317 13.167H8.1665V11.5003H9.83317V13.167Z'
      fill='#E53935'
    />
  </svg>
);

export const CustomBuildCircleRoundedIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
    >
      <g clipPath='url(#clip0_1702_4834)'>
        <path
          d='M6.045 1.45498C5.04 0.449977 3.535 0.244977 2.325 0.834977L4.14 2.64998C4.335 2.84498 4.335 3.15998 4.14 3.35498L3.345 4.14998C3.15 4.34998 2.835 4.34998 2.64 4.14998L0.825002 2.33498C0.240002 3.54998 0.445002 5.04498 1.45 6.04998C2.38 6.97998 3.74 7.22498 4.895 6.78998L8.875 10.77C9.39 11.285 10.22 11.285 10.73 10.77C11.245 10.255 11.245 9.42498 10.73 8.91498L6.77 4.94998C7.23 3.77998 6.99 2.39998 6.045 1.45498Z'
          fill='#4E5968'
        />
      </g>
      <defs>
        <clipPath id='clip0_1702_4834'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const historyIcons = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M4.64401 12.0018C4.64401 6.95178 8.81401 2.86178 13.904 3.00178C18.594 3.13178 22.514 7.05178 22.644 11.7418C22.784 16.8318 18.694 21.0018 13.644 21.0018C11.554 21.0018 9.64401 20.2918 8.12401 19.0918C7.65401 18.7318 7.62401 18.0218 8.04401 17.6018C8.40401 17.2418 8.96401 17.2118 9.36401 17.5218C10.544 18.4518 12.034 19.0018 13.644 19.0018C17.544 19.0018 20.694 15.8118 20.644 11.9018C20.594 8.18178 17.464 5.05178 13.744 5.00178C9.82401 4.95178 6.64401 8.10178 6.64401 12.0018H8.43401C8.88401 12.0018 9.10401 12.5418 8.79401 12.8518L6.00401 15.6518C5.80401 15.8518 5.49401 15.8518 5.29401 15.6518L2.50401 12.8518C2.18401 12.5418 2.40401 12.0018 2.85401 12.0018H4.64401ZM12.644 8.75178C12.644 8.34178 12.984 8.00178 13.394 8.00178C13.804 8.00178 14.144 8.34178 14.144 8.74178V12.1418L17.024 13.8518C17.374 14.0618 17.494 14.5218 17.284 14.8818C17.074 15.2318 16.614 15.3518 16.254 15.1418L13.134 13.2918C12.834 13.1118 12.644 12.7818 12.644 12.4318V8.75178Z'
        fill='#4E5968'
      />
    </svg>
  );
};
export const lockIcon = () => {
  return (
    <svg
      width='16'
      height='21'
      viewBox='0 0 16 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      tabIndex={0}
    >
      <path
        d='M14 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM8 16C6.9 16 6 15.1 6 14C6 12.9 6.9 12 8 12C9.1 12 10 12.9 10 14C10 15.1 9.1 16 8 16ZM5 7V5C5 3.34 6.34 2 8 2C9.66 2 11 3.34 11 5V7H5Z'
        fill='#6B7684'
      />
    </svg>
  );
};
export const questionHelpIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
    >
      <g clip-path='url(#clip0_6071_984)'>
        <path
          d='M9.99999 2.17334C5.39999 2.17334 1.66666 5.90667 1.66666 10.5067C1.66666 15.1067 5.39999 18.84 9.99999 18.84C14.6 18.84 18.3333 15.1067 18.3333 10.5067C18.3333 5.90667 14.6 2.17334 9.99999 2.17334ZM10.8333 16.34H9.16666V14.6733H10.8333V16.34ZM12.5583 9.88167L11.8083 10.6483C11.3917 11.0733 11.0917 11.4567 10.9417 12.0567C10.875 12.3233 10.8333 12.6233 10.8333 13.0067H9.16666V12.59C9.16666 12.2067 9.23332 11.84 9.34999 11.4983C9.51666 11.015 9.79166 10.5817 10.1417 10.2317L11.175 9.18167C11.5583 8.81501 11.7417 8.26501 11.6333 7.68167C11.525 7.08167 11.0583 6.57334 10.475 6.40667C9.54999 6.14834 8.69166 6.67334 8.41666 7.46501C8.31666 7.77334 8.05832 8.00667 7.73332 8.00667H7.48332C6.99999 8.00667 6.66666 7.54001 6.79999 7.07334C7.15832 5.84834 8.19999 4.91501 9.49166 4.71501C10.7583 4.51501 11.9667 5.17334 12.7167 6.21501C13.7 7.57334 13.4083 9.03167 12.5583 9.88167V9.88167Z'
          fill='#4E5968'
        />
      </g>
      <defs>
        <clipPath id='clip0_6071_984'>
          <rect
            width='20'
            height='20'
            fill='white'
            transform='translate(0 0.506836)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const loginInfo = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='227'
      height='219'
      viewBox='0 0 227 219'
      fill='none'
    >
      <g clip-path='url(#clip0_5920_7559)'>
        <path
          d='M221.808 101.042C220.032 81.6427 213.369 63.9024 203.13 49.0137L191.488 49.9019C186.695 50.2758 182.511 46.5595 182.16 41.6044L182.137 41.4174C181.833 37.0232 184.638 33.1199 188.659 32.0681C168.133 12.5748 140.15 1.54265 110.11 3.80985C68.1476 6.98861 33.7826 35.317 19.9197 73.3919L34.928 72.2467C39.7204 71.8727 43.905 75.589 44.2557 80.5442L44.2791 80.7311C44.6297 85.6863 41.0296 89.9869 36.2372 90.3375L14.9636 91.9503C13.4441 100.692 12.9298 109.761 13.6077 119.016C15.6649 147.766 28.4992 173.032 47.7389 190.959L69.1294 189.347C73.9218 188.973 78.1064 192.689 78.4571 197.644L78.4804 197.831C78.8077 202.576 75.5115 206.713 71.023 207.367C87.3171 215.782 105.972 219.942 125.469 218.446C166.123 215.361 199.623 188.715 214.304 152.44L198.898 153.609C194.106 153.983 189.921 150.266 189.571 145.311L189.547 145.124C189.197 140.169 192.797 135.869 197.589 135.518L219.938 133.835C221.411 126.823 222.182 119.554 222.252 112.145C222.252 112.145 222.019 103.216 221.808 101.042Z'
          fill='#E0F7FB'
        />
        <path
          d='M48.1705 117.128C48.1705 120.4 45.5989 123.041 42.4196 123.041C39.2402 123.041 36.6687 120.4 36.6687 117.128C36.6687 113.856 39.2402 111.214 42.4196 111.214C45.5989 111.214 48.1705 113.856 48.1705 117.128Z'
          fill='#4DCFE6'
        />
        <path
          d='M209.036 119.126C209.036 121.3 207.329 123.076 205.202 123.076C203.074 123.076 201.368 121.3 201.368 119.126C201.368 116.953 203.074 115.176 205.202 115.176C207.329 115.176 209.036 116.929 209.036 119.126Z'
          fill='#4DCFE6'
        />
        <path
          d='M89.7952 27.1129C89.7952 29.0062 88.299 30.5488 86.4522 30.5488C84.6054 30.5488 83.1092 29.0062 83.1092 27.1129C83.1092 25.2197 84.6054 23.6771 86.4522 23.6771C88.299 23.6771 89.7952 25.2197 89.7952 27.1129Z'
          fill='#4DCFE6'
        />
        <path
          d='M79.6259 144.33L89.6783 147.415L87.2938 169.222C87.2938 169.222 84.9092 166.137 85.0261 164.454C85.143 162.771 76.0024 162.28 74.3192 162.701C72.636 163.122 70.1814 165.786 70.1814 165.786C70.1814 165.786 75.6517 167.539 80.9117 170.882C86.1716 174.224 86.7327 173.289 86.7327 173.289C86.7327 173.289 86.4755 175.86 86.0781 178.945C85.5638 182.896 85.0261 187.781 86.569 190.001C86.569 190.001 69.8073 185.163 68.732 185.022C67.6566 184.906 67.2358 185.513 68.1475 186.028C69.0593 186.518 77.8025 190.655 79.0649 191.076C80.3272 191.497 84.4183 192.549 86.382 193.343C88.3457 194.138 89.4679 194.886 89.4679 194.886L55.4874 194.272L54.1911 184.368L60.9706 162.023L74.0153 146.597L79.6259 144.33Z'
          fill='#CC9393'
        />
        <path
          d='M91.2212 133.905C91.2212 133.905 86.873 133.812 85.5872 140.006C84.3014 146.2 89.6783 147.275 89.6783 147.275L91.2212 133.905Z'
          fill='#B2726F'
        />
        <path
          d='M80.4675 151.482C80.4909 151.435 80.4909 151.388 80.4909 151.388C80.4909 151.388 80.4675 151.435 80.4675 151.482Z'
          fill='#B2726F'
        />
        <path
          d='M74.3426 162.724C76.0258 162.304 85.1664 162.818 85.0495 164.477C84.9326 166.16 87.3171 169.246 87.3171 169.246L88.6964 156.507C86.5223 156.764 84.3482 159.639 81.5896 159.125C78.8077 158.611 80.2805 152.277 80.4909 151.482C80.3506 152.02 79.509 155.245 78.2934 158.12C77.405 160.2 75.2076 161.977 73.7815 162.935C73.9452 162.841 74.1556 162.771 74.3426 162.724Z'
          fill='#B2726F'
        />
        <path
          d='M74.2958 169.643C75.979 172.05 75.1842 177.824 75.0439 179.319C74.927 180.815 76.1193 176.071 80.4675 175.673C82.8053 175.463 85.0963 175.393 86.5223 175.252C86.7093 174.131 86.569 173.313 86.569 173.313C86.569 173.313 86.195 174.224 80.9351 170.882C75.6751 167.539 70.2048 165.786 70.2048 165.786L63.4019 167.867L63.8928 168.755C63.8928 168.778 72.5893 167.212 74.2958 169.643Z'
          fill='#B2726F'
        />
        <path
          d='M79.0649 191.146C77.8025 190.726 69.0826 186.588 68.1475 186.098C67.2358 185.607 67.6566 184.976 68.732 185.093C69.8073 185.209 86.5691 190.071 86.5691 190.071C86.008 189.276 85.6807 188.108 85.5171 186.752C82.1039 186.144 75.6284 184.812 74.2257 183.012C72.2854 180.558 72.7295 180.371 70.9061 181.563C69.0826 182.755 65.5994 184.999 63.4019 184.999C61.2044 184.999 58.6329 184.812 58.6329 184.812L58.6095 186.191C58.6095 186.191 60.0823 186.892 62.9577 187.079C65.8332 187.266 71.1165 189.58 74.4829 190.772C76.7505 191.567 89.0471 196.172 92.9044 197.457L89.4679 194.933C89.4679 194.933 88.3458 194.185 86.382 193.39C84.3949 192.595 80.3039 191.544 79.0649 191.146Z'
          fill='#B2726F'
        />
        <path
          d='M89.4679 194.933C89.4679 194.933 95.359 197.644 93.2784 200.846C83.3617 211.392 52.6575 193.706 52.6575 193.706L89.4679 194.933Z'
          fill='#CC9393'
        />
        <rect
          x='93.5496'
          y='34.0676'
          width='78.4302'
          height='161.552'
          rx='10.0823'
          transform='rotate(5.76315 93.5496 34.0676)'
          fill='#070707'
        />
        <rect
          x='96.7258'
          y='37.9568'
          width='71.329'
          height='154.451'
          rx='6.7215'
          transform='rotate(5.76315 96.7258 37.9568)'
          fill='#1D1D1D'
        />
        <mask
          id='mask0_5920_7559'
          maskUnits='userSpaceOnUse'
          x='81'
          y='38'
          width='87'
          height='161'
        >
          <rect
            x='96.7258'
            y='37.9568'
            width='71.329'
            height='154.451'
            rx='6.7215'
            transform='rotate(5.76315 96.7258 37.9568)'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_5920_7559)'>
          <rect
            x='96.7727'
            y='38.0143'
            width='71.3367'
            height='154.468'
            transform='rotate(5.76315 96.7727 38.0143)'
            fill='white'
          />
          <rect
            x='93.3453'
            y='183.743'
            width='18.6426'
            height='5.32647'
            rx='1.34444'
            transform='rotate(5.76315 93.3453 183.743)'
            fill='#E5E5E5'
          />
          <rect
            x='133.205'
            y='188.531'
            width='12.9357'
            height='3.80462'
            rx='1.34444'
            transform='rotate(5.76316 133.205 188.531)'
            fill='#E5E5E5'
          />
          <rect
            x='95.0471'
            y='164.986'
            width='18.6426'
            height='5.32647'
            rx='1.34444'
            transform='rotate(5.76315 95.0471 164.986)'
            fill='#E5E5E5'
          />
          <rect
            x='134.907'
            y='169.774'
            width='12.9357'
            height='3.80462'
            rx='1.34444'
            transform='rotate(5.76315 134.907 169.774)'
            fill='#E5E5E5'
          />
          <rect
            x='93.3404'
            y='147.797'
            width='34.0514'
            height='3.80462'
            rx='1.34444'
            transform='rotate(5.76314 93.3404 147.797)'
            fill='#E5E5E5'
          />
          <rect
            x='92.5382'
            y='155.747'
            width='12.9357'
            height='3.80462'
            rx='1.34444'
            transform='rotate(5.76316 92.5382 155.747)'
            fill='#E5E5E5'
          />
          <rect
            width='4.56556'
            height='4.56552'
            rx='1.34444'
            transform='matrix(0.994946 0.100416 -0.100417 0.994945 144.671 152.596)'
            fill='#E5E5E5'
          />
          <rect
            width='2.28278'
            height='2.28277'
            rx='0.672222'
            transform='matrix(0.994946 0.100416 -0.100417 0.994945 144.889 161.795)'
            fill='#E5E5E5'
          />
          <rect
            x='94.1493'
            y='173.882'
            width='18.6427'
            height='5.32647'
            rx='1.34444'
            transform='rotate(5.76315 94.1493 173.882)'
            fill='#E5E5E5'
          />
          <rect
            x='134.009'
            y='178.67'
            width='12.9357'
            height='3.80462'
            rx='1.34444'
            transform='rotate(5.76315 134.009 178.67)'
            fill='#E5E5E5'
          />
          <line
            x1='92.5865'
            y1='153.373'
            x2='148.989'
            y2='159.065'
            stroke='#E4E4E4'
            stroke-width='0.168056'
          />
          <line
            x1='91.8033'
            y1='161.133'
            x2='148.206'
            y2='166.825'
            stroke='#E4E4E4'
            stroke-width='0.168056'
          />
          <line
            x1='94.5763'
            y1='171.546'
            x2='147.193'
            y2='176.857'
            stroke='#E4E4E4'
            stroke-width='0.168056'
          />
          <line
            x1='92.6087'
            y1='191.041'
            x2='145.226'
            y2='196.351'
            stroke='#E4E4E4'
            stroke-width='0.168056'
          />
          <line
            x1='93.583'
            y1='181.388'
            x2='146.2'
            y2='186.699'
            stroke='#E4E4E4'
            stroke-width='0.168056'
          />
          <rect
            x='96.7727'
            y='38.0143'
            width='71.3367'
            height='19.784'
            transform='rotate(5.76315 96.7727 38.0143)'
            fill='#00BADB'
          />
          <rect
            x='159.712'
            y='56.603'
            width='4.56554'
            height='4.56554'
            rx='2.28277'
            transform='rotate(5.76316 159.712 56.603)'
            fill='#80DDED'
          />
          <rect
            x='99.3354'
            y='50.5094'
            width='4.56554'
            height='4.56555'
            rx='0.672222'
            transform='rotate(5.76316 99.3354 50.5094)'
            fill='#80DDED'
          />
          <rect
            x='108.004'
            y='51.7667'
            width='34.0514'
            height='3.80462'
            rx='0.672222'
            transform='rotate(5.76315 108.004 51.7667)'
            fill='#80DDED'
          />
          <rect
            x='103.08'
            y='40.0992'
            width='8.17993'
            height='2.88229'
            rx='0.672222'
            transform='rotate(5.76316 103.08 40.0992)'
            fill='#80DDED'
          />
          <rect
            x='152.405'
            y='45.0775'
            width='2.88228'
            height='2.88229'
            rx='0.672222'
            transform='rotate(5.76318 152.405 45.0775)'
            fill='#80DDED'
          />
          <rect
            x='156.15'
            y='45.4554'
            width='2.88229'
            height='2.88229'
            rx='0.672222'
            transform='rotate(5.76314 156.15 45.4554)'
            fill='#80DDED'
          />
          <rect
            x='159.861'
            y='45.8299'
            width='2.8823'
            height='2.88229'
            rx='0.672222'
            transform='rotate(5.76312 159.861 45.8299)'
            fill='#80DDED'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M144.34 134.466C143.712 134.233 143.523 134.039 143.55 133.769C143.581 133.46 143.89 133.273 144.369 133.321C144.875 133.372 145.038 133.633 145.019 133.989L145.646 134.052C145.676 133.562 145.423 133.084 144.844 132.879L144.907 132.257L144.055 132.171L143.993 132.784C143.431 132.848 142.952 133.161 142.896 133.709C142.83 134.365 143.339 134.746 144.112 135.016C144.805 135.258 144.922 135.522 144.895 135.786C144.875 135.982 144.705 136.28 144.077 136.217C143.492 136.158 143.289 135.874 143.291 135.536L142.667 135.473C142.638 136.098 143.068 136.494 143.602 136.665L143.54 137.281L144.391 137.367L144.453 136.757C145.017 136.708 145.489 136.431 145.548 135.849C145.63 135.043 144.968 134.698 144.34 134.466Z'
            fill='white'
          />
          <mask
            id='mask1_5920_7559'
            maskUnits='userSpaceOnUse'
            x='142'
            y='132'
            width='4'
            height='6'
          >
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M144.34 134.466C143.712 134.233 143.523 134.039 143.55 133.769C143.581 133.46 143.89 133.273 144.369 133.321C144.875 133.372 145.038 133.633 145.019 133.989L145.646 134.052C145.676 133.562 145.423 133.084 144.844 132.879L144.907 132.257L144.055 132.171L143.993 132.784C143.431 132.848 142.952 133.161 142.896 133.709C142.83 134.365 143.339 134.746 144.112 135.016C144.805 135.258 144.922 135.522 144.895 135.786C144.875 135.982 144.705 136.28 144.077 136.217C143.492 136.158 143.289 135.874 143.291 135.536L142.667 135.473C142.638 136.098 143.068 136.494 143.602 136.665L143.54 137.281L144.391 137.367L144.453 136.757C145.017 136.708 145.489 136.431 145.548 135.849C145.63 135.043 144.968 134.698 144.34 134.466Z'
              fill='white'
            />
          </mask>
          <g mask='url(#mask1_5920_7559)'>
            <rect
              x='141.302'
              y='131.033'
              width='6.84831'
              height='6.84832'
              transform='rotate(5.76316 141.302 131.033)'
              fill='#E5E5E5'
            />
          </g>
          <g clip-path='url(#clip1_5920_7559)'>
            <path
              d='M103.33 128.237L103.364 127.892C103.375 127.792 103.301 127.702 103.201 127.692C103.106 127.683 103.026 127.748 103.003 127.837L101.872 127.723C101.872 127.631 101.802 127.551 101.707 127.541C101.607 127.531 101.517 127.605 101.507 127.705L101.472 128.05C100.384 128.326 99.5331 129.254 99.4144 130.43C99.2626 131.934 100.361 133.279 101.865 133.431C103.368 133.583 104.714 132.484 104.865 130.981C104.988 129.809 104.34 128.725 103.33 128.237ZM102.787 129.523C102.907 129.425 103.059 129.376 103.209 129.391L103.213 129.391C103.522 129.422 103.754 129.712 103.724 130.016C103.709 130.162 103.635 130.301 103.515 130.399C103.395 130.497 103.239 130.546 103.089 130.531C102.785 130.5 102.547 130.214 102.579 129.905C102.594 129.755 102.667 129.621 102.787 129.523ZM100.934 129.336C101.054 129.238 101.205 129.189 101.355 129.204L101.36 129.204C101.669 129.235 101.901 129.525 101.87 129.829C101.856 129.975 101.782 130.114 101.662 130.212C101.542 130.31 101.386 130.359 101.236 130.343C100.931 130.313 100.694 130.027 100.725 129.718C100.74 129.568 100.814 129.434 100.934 129.336ZM100.845 131.401C100.84 131.4 100.835 131.4 100.835 131.4C100.622 131.378 100.459 131.178 100.48 130.965C100.502 130.751 100.697 130.592 100.91 130.613L100.915 130.614C101.128 130.635 101.288 130.826 101.271 131.04C101.254 131.254 101.063 131.418 100.845 131.401ZM102.386 132.318C102.265 132.416 102.109 132.464 101.959 132.449C101.655 132.419 101.418 132.133 101.449 131.824C101.464 131.674 101.537 131.539 101.657 131.441C101.777 131.343 101.929 131.294 102.079 131.31L102.083 131.31C102.392 131.341 102.625 131.631 102.594 131.935C102.579 132.081 102.506 132.22 102.386 132.318ZM103.302 131.649C103.297 131.648 103.293 131.648 103.293 131.648C103.079 131.626 102.916 131.426 102.938 131.213C102.959 130.999 103.154 130.84 103.368 130.861L103.372 130.862C103.586 130.883 103.745 131.074 103.728 131.288C103.711 131.502 103.52 131.666 103.302 131.649Z'
              fill='#E5E5E5'
            />
            <path
              d='M101.907 133.013C103.161 133.14 104.281 132.225 104.407 130.971C104.534 129.717 103.62 128.597 102.365 128.471C101.111 128.344 99.9915 129.258 99.8649 130.513C99.7384 131.767 100.653 132.886 101.907 133.013Z'
              stroke='white'
              stroke-width='0.242'
              stroke-miterlimit='10'
            />
          </g>
          <rect
            x='96.8152'
            y='136.103'
            width='9.51155'
            height='2.28277'
            rx='1.14139'
            transform='rotate(5.76315 96.8152 136.103)'
            fill='#E5E5E5'
          />
          <rect
            x='117.824'
            y='138.223'
            width='9.51156'
            height='2.28277'
            rx='1.14139'
            transform='rotate(5.76315 117.824 138.223)'
            fill='#E5E5E5'
          />
          <rect
            x='139.022'
            y='140.363'
            width='9.51157'
            height='2.28277'
            rx='1.14139'
            transform='rotate(5.76314 139.022 140.363)'
            fill='#E5E5E5'
          />
          <rect
            x='140.193'
            y='143.922'
            width='6.08741'
            height='1.52186'
            rx='0.760928'
            transform='rotate(5.76317 140.193 143.922)'
            fill='#E5E5E5'
          />
          <rect
            x='119.184'
            y='141.802'
            width='6.08739'
            height='1.52185'
            rx='0.760923'
            transform='rotate(5.76315 119.184 141.802)'
            fill='#E5E5E5'
          />
          <rect
            width='6.08739'
            height='1.52186'
            rx='0.760928'
            transform='matrix(0.994945 0.100417 -0.100416 0.994946 98.1747 139.682)'
            fill='#E5E5E5'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M124.847 132.226C124.447 132.186 124.047 132.145 123.647 132.105C123.737 131.795 123.827 131.485 123.917 131.175C124.027 130.799 124.136 130.422 124.245 130.046C124.245 130.046 124.246 130.045 124.246 130.045C124.287 129.903 124.119 129.812 124.022 129.923C123.845 130.125 123.668 130.326 123.492 130.528C123.132 130.938 122.773 131.348 122.413 131.758C122.196 132.006 121.978 132.254 121.761 132.502L121.761 132.502C121.692 132.581 121.623 132.66 121.553 132.739C121.517 132.781 121.481 132.822 121.444 132.864C121.378 132.939 121.445 133.058 121.535 133.067C121.935 133.107 122.335 133.148 122.735 133.188C122.645 133.498 122.555 133.808 122.465 134.118C122.355 134.494 122.246 134.871 122.137 135.247C122.137 135.247 122.137 135.248 122.136 135.248C122.095 135.39 122.263 135.481 122.36 135.37C122.537 135.168 122.714 134.967 122.891 134.765C123.25 134.355 123.609 133.945 123.969 133.535C124.186 133.287 124.404 133.039 124.621 132.791L124.621 132.791C124.69 132.712 124.76 132.633 124.829 132.554C124.865 132.512 124.902 132.471 124.938 132.429C125.004 132.354 124.937 132.235 124.847 132.226Z'
            fill='#E5E5E5'
          />
          <rect
            x='116.874'
            y='121.111'
            width='14.838'
            height='4.18509'
            rx='2.09254'
            transform='rotate(5.76315 116.874 121.111)'
            fill='#89CB00'
            fill-opacity='0.8'
          />
          <rect
            x='108.31'
            y='109.348'
            width='34.6221'
            height='5.32647'
            rx='1.34444'
            transform='rotate(5.76315 108.31 109.348)'
            fill='#E5E5E5'
          />
          <rect
            x='116.576'
            y='116.492'
            width='16.9306'
            height='2.28277'
            rx='1.14139'
            transform='rotate(5.76315 116.576 116.492)'
            fill='#E5E5E5'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M128.403 107.935L132.551 108.353L132.986 104.043C132.999 103.914 132.918 103.794 132.799 103.782L129.075 103.406C128.955 103.394 128.851 103.495 128.838 103.625L128.403 107.935Z'
            fill='#4D4B4B'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M129.863 108.082L132.363 108.334C131.191 104.775 130.545 100.208 131.054 97.6378L128.781 97.1849C128.236 99.9183 128.76 104.322 129.863 108.082Z'
            fill='#191A1A'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M114.333 106.514L116.68 106.751C118.625 100.275 121.227 95.406 121.448 94.9933C121.456 94.9777 121.461 94.9685 121.462 94.9658L120.448 94.4071L119.433 93.8485C119.365 93.972 117.78 96.8566 116.096 101.268C115.525 102.764 114.905 104.545 114.333 106.514Z'
            fill='#191A1A'
          />
          <path
            d='M134.931 96.0272L134.515 100.156L132.795 100.402L133.273 95.6643L134.931 96.0272Z'
            fill='#707070'
          />
          <path
            d='M131.814 96.1036L131.431 99.8918L132.871 100.391L133.371 95.4321L131.814 96.1036Z'
            fill='#191A1A'
          />
          <path
            d='M126.435 98.1026L126.262 99.8169L127.163 100.299L127.498 100.482L129.369 100.671L130.996 100.611L131.251 98.0766L126.435 98.1026Z'
            fill='#F7F7F6'
          />
          <path
            d='M130.996 100.611L131.251 98.0766L129.434 98.0888L129.246 99.9506L127.569 99.7813L127.739 98.0946L127.264 98.0932L127.109 99.6324L126.521 99.1821L126.63 98.1037L126.435 98.1026L126.262 99.8169L127.163 100.299L127.498 100.482L129.369 100.671L130.996 100.611Z'
            fill='#707070'
          />
          <path
            d='M135.842 67.7209C135.842 67.7209 138.402 67.4485 139.238 71.4434C140.073 75.4383 140.564 80.2643 139.175 86.9211C137.786 93.5779 135.776 95.6841 135.776 95.6841L135.493 96.9218L131.597 97.1431L135.842 67.7209Z'
            fill='#191A1A'
          />
          <path
            d='M131.1 64.7935C131.1 64.7935 135.246 65.1375 136.026 66.8269C136.796 68.5154 138.014 75.2677 137.344 82.5464C136.676 89.816 134.128 98.2645 131.166 98.5522C128.204 98.8398 127.856 98.4136 127.856 98.4136L131.1 64.7935Z'
            fill='#AFAFAF'
          />
          <path
            d='M131.1 64.7935C132.51 64.9358 135.741 65.9602 136.158 68.7489C136.863 73.4756 137.208 79.9257 136.388 84.1817C135.568 88.4285 133.985 96.0899 132.034 97.6155C130.092 99.142 124.867 98.8754 123.101 97.4588C121.344 96.0432 118.847 92.0016 118.648 85.2963C118.451 78.5818 121.142 70.6413 123.168 67.6427C123.167 67.652 124.353 64.1125 131.1 64.7935Z'
            fill='#F3F3F3'
          />
          <path
            d='M122.54 75.8848C122.54 75.8848 123.849 71.8735 125.946 71.7593C128.043 71.6451 130.917 71.4044 131.785 73.5032C132.664 75.5937 122.54 75.8848 122.54 75.8848Z'
            fill='#20ADD0'
          />
          <path
            d='M125.987 72.2755C125.987 72.2755 127.515 71.9921 129.497 72.4622C131.48 72.9323 131.897 73.412 132.309 74.4033C132.721 75.3946 134.409 82.9392 133.719 86.3611C133.028 89.7923 131.228 94.6199 128.386 94.4541C125.544 94.2883 122.597 93.7767 122.369 92.2546C122.14 90.7418 120.922 89.2501 120.922 89.2501C120.922 89.2501 120 86.4756 120.394 82.5768C120.795 78.6974 123.653 72.431 125.987 72.2755Z'
            fill='#505051'
          />
          <path
            d='M121.099 88.3184C121.099 88.3184 119.87 85.0937 121.59 80.9657C123.31 76.8377 124.467 76.6286 125.667 76.5541C126.866 76.4797 130.994 76.6356 131.975 78.8202C132.956 81.0048 133.822 84.9656 133.388 87.054C132.954 89.1424 131.098 94.8861 127.07 94.0233C123.041 93.1605 121.791 92.904 121.791 92.904L121.099 88.3184Z'
            fill='#333333'
          />
          <path
            d='M123.836 84.5444C123.836 84.5444 123.765 79.7142 124.939 79.2461C126.113 78.778 129.791 79.1492 130.828 79.4866C131.865 79.8148 132.67 83.4714 132.734 84.7721C132.798 86.0728 132.73 87.3973 130.998 87.7626C129.266 88.1278 126.495 87.9133 126.495 87.9133L123.836 84.5444Z'
            fill='#191A1A'
          />
          <path
            d='M124.485 83.0084C124.485 83.0084 123.633 83.4251 122.626 85.2788C121.629 87.1335 119.876 90.9324 119.876 90.9324C119.876 90.9324 119.256 92.5643 119.193 92.9117C119.193 92.9117 121.924 92.6009 122.12 94.5386C122.12 94.5386 123.722 92.2237 124.015 91.5363C124.308 90.8489 125.763 88.426 125.763 88.426C125.763 88.426 126.858 88.1827 127.38 87.9094C127.891 87.6352 129.442 86.8513 129.563 85.6531C129.683 84.4641 130.213 82.7205 128.627 82.0111C127.033 81.2824 124.485 83.0084 124.485 83.0084Z'
            fill='#E6E4E4'
          />
          <path
            d='M127.451 80.7473C127.451 80.7473 124.453 82.4 123.541 84.3285C122.63 86.2569 123.139 86.0104 123.139 86.0104L124.04 86.12L127.834 82.3875C127.834 82.3875 129.391 82.7401 129.549 84.7765C129.549 84.7765 129.391 86.0641 129.008 86.6306C129.008 86.6306 131.097 85.5845 130.476 83.3431C129.856 81.0832 128.45 80.8109 127.451 80.7473Z'
            fill='#242529'
          />
          <path
            d='M123.19 90.8572C123.134 90.9447 122.949 90.9353 122.773 90.8337C122.597 90.7322 122.502 90.5643 122.558 90.4768C122.613 90.3893 122.798 90.3987 122.974 90.5002C123.149 90.611 123.245 90.7696 123.19 90.8572Z'
            fill='#525050'
          />
          <path
            d='M124.783 87.5263C124.727 87.6138 124.542 87.6044 124.366 87.5029C124.19 87.4013 124.095 87.2335 124.151 87.1459C124.206 87.0584 124.392 87.0678 124.567 87.1694C124.743 87.2801 124.838 87.4388 124.783 87.5263Z'
            fill='#525050'
          />
          <path
            d='M128.626 84.3202C128.544 84.3957 128.374 84.3319 128.231 84.1778C128.098 84.0247 128.051 83.8431 128.132 83.7768C128.214 83.7013 128.384 83.765 128.527 83.9191C128.662 84.063 128.709 84.2446 128.626 84.3202Z'
            fill='#525050'
          />
          <path
            d='M125.185 86.8686C125.139 86.9478 124.983 86.932 124.843 86.8434C124.703 86.7548 124.622 86.6256 124.668 86.5464C124.713 86.4672 124.87 86.483 125.01 86.5716C125.159 86.6612 125.239 86.7903 125.185 86.8686Z'
            fill='#525050'
          />
          <path
            d='M125.763 88.426C125.763 88.426 126.858 88.1827 127.38 87.9094C127.822 87.6748 129.001 87.0675 129.418 86.1599C129.344 86.0686 129.285 86.0068 129.272 86.0427C129.243 86.1422 127.048 87.7828 126.828 87.7421C126.609 87.7013 125.427 87.6006 125.427 87.6006C125.427 87.6006 123.557 90.8196 123.133 91.6055C122.709 92.3914 122.962 93.3015 122.962 93.3015C123.401 92.6382 123.867 91.8938 124.023 91.5464C124.308 90.8489 125.763 88.426 125.763 88.426Z'
            fill='#C2C2C1'
          />
          <path
            d='M126.202 80.6677C126.176 80.9258 125.942 81.1164 125.684 81.0904C125.426 81.0643 125.235 80.8309 125.261 80.5729C125.287 80.3148 125.521 80.1242 125.779 80.1502C126.037 80.1763 126.228 80.4097 126.202 80.6677Z'
            fill='#0F0F0E'
          />
          <path
            d='M131.293 81.1444C131.268 81.3932 131.045 81.5755 130.796 81.5504C130.547 81.5253 130.365 81.302 130.39 81.0532C130.415 80.8043 130.638 80.622 130.887 80.6471C131.137 80.663 131.32 80.8863 131.293 81.1444Z'
            fill='#0F0F0E'
          />
          <path
            d='M131.699 86.1666C131.674 86.4154 131.451 86.5978 131.202 86.5726C130.953 86.5475 130.771 86.3243 130.796 86.0754C130.821 85.8266 131.044 85.6442 131.293 85.6694C131.542 85.6945 131.724 85.9177 131.699 86.1666Z'
            fill='#0F0F0E'
          />
          <path
            d='M127.927 75.1064L127.023 75.0152C126.95 75.0077 126.892 74.9367 126.899 74.863L126.957 74.2915C126.964 74.2178 127.035 74.1598 127.109 74.1672L128.012 74.2584C128.086 74.2658 128.144 74.3369 128.137 74.4106L128.079 74.9821C128.062 75.0549 128.001 75.1138 127.927 75.1064ZM127.103 74.2317C127.056 74.2271 127.025 74.2612 127.021 74.298L126.964 74.8695C126.959 74.9156 126.993 74.9469 127.03 74.9507L127.933 75.0418C127.979 75.0465 128.011 75.0124 128.014 74.9755L128.072 74.4041C128.077 74.358 128.043 74.3266 128.006 74.3229L127.103 74.2317Z'
            fill='#FFFDFF'
          />
          <path
            d='M128.076 74.4603L126.979 74.3496L126.973 74.4049L128.07 74.5156L128.076 74.4603Z'
            fill='#FFFDFF'
          />
          <path
            d='M128.205 75.0227L128.153 74.9895C128.411 74.6431 128.196 74.4724 128.197 74.4632L128.229 74.4199C128.238 74.4208 128.497 74.6239 128.205 75.0227Z'
            fill='#FFFDFF'
          />
          <path
            d='M128.319 75.1925L128.276 75.151C128.706 74.766 128.367 74.2477 128.368 74.2385L128.418 74.2063C128.426 74.2164 128.789 74.7744 128.319 75.1925Z'
            fill='#FFFDFF'
          />
          <path
            d='M126.73 74.8739C126.524 74.4248 126.819 74.2776 126.819 74.2776L126.842 74.3265C126.831 74.3348 126.595 74.4599 126.779 74.8509L126.73 74.8739Z'
            fill='#FFFDFF'
          />
          <path
            d='M126.586 75.0175C126.208 74.5139 126.675 74.0396 126.685 74.0313L126.727 74.0728C126.707 74.0894 126.291 74.5223 126.635 74.9853L126.586 75.0175Z'
            fill='#FFFDFF'
          />
          <path
            d='M126.27 73.8964L126.215 73.8908L126.236 73.6788C126.242 73.6235 126.255 73.5876 126.285 73.5627C126.326 73.5296 126.363 73.5333 126.4 73.537L126.584 73.5556L126.578 73.6109L126.403 73.5933C126.376 73.5905 126.348 73.5877 126.328 73.6043C126.318 73.6125 126.306 73.6393 126.302 73.6761L126.28 73.8973L126.27 73.8964Z'
            fill='#FFFDFF'
          />
          <path
            d='M128.898 74.1615L128.842 74.1559L128.864 73.9439C128.867 73.9071 128.861 73.8785 128.853 73.8683C128.836 73.848 128.808 73.8452 128.79 73.8434L128.606 73.8248L128.612 73.7603L128.787 73.7779C128.824 73.7817 128.87 73.7863 128.894 73.826C128.919 73.8564 128.925 73.8942 128.919 73.9495L128.898 74.1615Z'
            fill='#FFFDFF'
          />
          <path
            d='M126.194 75.4809C126.157 75.4771 126.121 75.4734 126.087 75.4328C126.063 75.4024 126.057 75.3646 126.063 75.3093L126.084 75.0973L126.139 75.1029L126.118 75.3149C126.114 75.3517 126.121 75.3803 126.129 75.3905C126.146 75.4108 126.173 75.4135 126.192 75.4154L126.376 75.434L126.37 75.4893L126.195 75.4716C126.194 75.4809 126.194 75.4809 126.194 75.4809Z'
            fill='#FFFDFF'
          />
          <path
            d='M128.591 75.7227L128.406 75.7041L128.412 75.6488L128.587 75.6665C128.624 75.6702 128.643 75.6721 128.663 75.6555C128.673 75.6472 128.685 75.6205 128.689 75.5836L128.71 75.3716L128.765 75.3772L128.744 75.5892C128.738 75.6445 128.726 75.6805 128.695 75.7053C128.665 75.7302 128.628 75.7265 128.591 75.7227Z'
            fill='#FFFDFF'
          />
          <path
            d='M119.194 92.8002L118.704 94.7991C118.704 94.7991 119.94 94.6352 120.972 95.8566L122.121 94.5294C122.12 94.5386 122.595 92.7803 119.194 92.8002Z'
            fill='#191A1A'
          />
        </g>
        <path
          d='M86.1917 117.865C89.2074 120.459 89.9121 127.127 89.9121 127.127C89.9121 134.278 79.6026 144.353 79.6026 144.353C79.6026 144.353 68.8723 160.153 65.2488 165.295C61.6019 170.438 60.62 171.302 60.4797 175.813C60.3628 180.324 60.1057 179.623 59.1005 182.031C58.0952 184.461 58.7732 190.211 59.1005 191.427C59.4277 192.642 59.1005 218.184 59.1005 218.184L1.29513 218.184C1.29513 218.184 4.06592 184.27 8.08686 173.472C12.1078 162.673 17.7923 156.063 24.4549 154.684C31.0941 153.305 38.1308 154.193 39.5101 152.417C40.8893 150.664 50.823 140.05 54.0725 136.825C57.322 133.576 60.3856 128.294 62.2791 126.354C64.1493 124.391 75.3421 118.951 77.8435 116.45C80.3449 113.925 83.176 115.27 86.1917 117.865Z'
          fill='#EAC3BC'
        />
        <path
          d='M78.1265 117.723C75.1551 119.421 68.9294 123.241 68.9294 123.241L76.57 127.345C76.57 127.345 84.9259 125.93 85.2012 121.968C85.3211 120.241 85.1438 118.955 83.9277 117.723C82.5338 116.312 81.0979 116.025 78.1265 117.723Z'
          fill='#F7E7E4'
        />
        <path
          d='M113.14 39.486L151.369 43.3443L151.191 45.106C150.979 47.1989 149.112 48.7244 147.019 48.5131L116.369 45.4198C114.277 45.2086 112.751 43.3407 112.962 41.2477L113.14 39.486Z'
          fill='#070707'
        />
        <path
          d='M39.0425 152.814C39.0425 152.814 22.6548 158.377 20.5041 170.905C20.5041 170.905 25.3432 158.213 39.0425 152.814Z'
          fill='#B2726F'
        />
        <path
          d='M158.525 88.5845C158.525 88.5845 159.764 87.7197 159.975 88.9118C160.185 90.1038 158.291 90.8985 158.291 90.8985C158.291 90.8985 156.585 89.2857 157.239 88.3742C157.871 87.4392 158.525 88.5845 158.525 88.5845Z'
          stroke='white'
          stroke-width='0.25'
          stroke-miterlimit='10'
        />
        <path
          d='M127.199 145.428C127.199 145.428 124.932 152.206 130.285 156.133C135.638 160.06 132.553 158.307 137.065 161.556C141.576 164.805 140.735 163.402 143.26 164.057C145.761 164.735 146.766 165.81 149.104 169.246C151.442 172.681 152.868 175.93 151.956 177.777C151.044 179.623 151.442 181.446 152.237 183.036C153.031 184.625 153.031 188.061 152.798 191.216C152.54 194.395 165.287 215.071 165.287 215.071H211.272C211.272 215.071 201.587 160.971 193.054 157.465C184.521 153.959 174.913 142.6 169.396 137.996C163.879 133.391 162.429 133.204 162.429 133.204L161.12 143.956C161.12 143.956 152.424 138.229 147.491 139.912C142.558 141.595 141.553 142.577 137.789 142.577C134.049 142.577 129.467 140.917 127.199 145.428Z'
          fill='#EAC3BC'
        />
        <path
          d='M156 190.375C157.684 187.944 163.224 190.866 158.876 189.043C154.528 187.196 156.281 187.453 155.626 185.7C154.948 183.947 153.452 174.341 152.283 171.489C151.114 168.638 144.592 162.374 143.166 159.896C141.74 157.419 142.418 155.105 142.091 153.445C141.763 151.786 140.665 157.021 141.015 159.289C141.343 161.556 137.93 156.951 133.067 154.778C130.823 153.772 128.602 152.019 126.872 150.43C127.176 152.347 128.088 154.474 130.308 156.11C135.662 160.037 132.576 158.284 137.088 161.532C141.6 164.781 140.758 163.379 143.283 164.033C145.784 164.711 146.79 165.786 149.127 169.222C151.465 172.658 152.891 175.907 151.979 177.753C151.068 179.6 151.465 181.423 152.26 183.012C153.055 184.602 153.055 188.038 152.821 191.193C152.564 194.372 164.229 212.626 164.229 212.626L155.042 194.933C155.276 193.53 155.065 191.731 156 190.375Z'
          fill='#B2726F'
        />
        <path
          d='M169.162 152.183C172.762 156.367 179.191 164.711 182.698 166.815C186.204 168.895 181.201 164.594 183.773 163.075C186.368 161.556 185.947 159.873 186.041 159.639C186.134 159.382 186.134 158.961 186.134 158.728C186.134 158.47 185.386 161.065 182.534 161.976C179.682 162.888 179.775 162.07 177.274 159.382C174.773 156.717 161.214 143.558 161.214 143.558L161.167 143.979L169.162 152.183Z'
          fill='#B2726F'
        />
        <path
          d='M133.515 142.343C133.515 142.343 131.534 142.343 131.109 142.909C130.844 143.263 130.826 143.758 131.109 144.041C132.946 145.877 137.271 145.173 137.271 145.173L139.175 142.343H133.515Z'
          fill='#F7E7E4'
        />
      </g>
      <defs>
        <clipPath id='clip0_5920_7559'>
          <rect
            width='226.108'
            height='218.75'
            fill='white'
            transform='translate(0.446167)'
          />
        </clipPath>
        <clipPath id='clip1_5920_7559'>
          <rect
            width='6.84831'
            height='6.84833'
            fill='white'
            transform='translate(99.0952 126.773) rotate(5.76316)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

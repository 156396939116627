import { Grid, Typography, List } from '@mui/material';
import { settinStyles } from './styles';
import AccountMenuItem from './account-menu-item.component';
import {
  helpIcon,
  notificationIcon,
  paymentIcon,
  personalInfoIcon,
  rfidIcon,
  termsIcon,
  translateIcon,
} from '../../../assets/icons/icons';
import { ROUTES } from '../../../types/routes.enum';
import { useTranslation } from 'react-i18next';
import { getCurrentLanguage } from './utils';
import { useGetInvoiceBalanceQuery } from '../../../services/invoices.api';
import { format2Digits } from '../../../util/numbers-utility';
import { ClassNameMap, withStyles } from '@mui/styles';
import { useAuth, USER_ROLE } from '../../../hooks/useAuth';
interface IProps {
  classes: ClassNameMap;
}
const AccountMenu = ({ classes }: IProps) => {
  const { data: balance } = useGetInvoiceBalanceQuery();

  const { i18n } = useTranslation();
  const auth = useAuth();

  return (
    <div>
      <Typography className={classes.headingFirst}>
        {i18n.t('my_account')}
      </Typography>
      <Grid className={classes.itemWrapper}>
        <List>
          <AccountMenuItem
            path={`${ROUTES.PROFILE}`}
            title={i18n.t('personal_info')}
            icon={personalInfoIcon}
            ariaLabel={i18n.t('open_personal_info')}
          />
          <AccountMenuItem
            path={ROUTES.RFID}
            title={i18n.t('rfid_cards')}
            icon={rfidIcon}
            ariaLabel={i18n.t('open_rfid_cards')}
          />
          {auth.role == USER_ROLE.SUPPORT ? (
            <AccountMenuItem
              path={ROUTES.PLUG_AND_CHARGE_KEYS}
              title={i18n.t('plug_and_charge_keys')}
              icon={rfidIcon}
              ariaLabel={i18n.t('open_plug_and_charge_keys')}
            />
          ) : null}
          <AccountMenuItem
            path={ROUTES.NOTIFICATION}
            title={i18n.t('notification_title')}
            icon={notificationIcon}
            ariaLabel={i18n.t('open_notification_settings')}
          />
          <AccountMenuItem
            path={ROUTES.LANGUAGE}
            title={`${i18n.t('language')}: ${i18n.t(
              getCurrentLanguage(i18n.language),
            )}`}
            icon={translateIcon}
            ariaLabel={i18n.t('open_language_settings')}
          />
        </List>
      </Grid>
      <Typography className={classes.heading}>{i18n.t('activity')}</Typography>
      <Grid className={classes.itemWrapper}>
        <List>
          <AccountMenuItem
            path={`${ROUTES.PAYMENTS}`}
            title={i18n.t('payments')}
            icon={paymentIcon}
            hasTopMargin={false}
            ariaLabel={i18n.t('open_payment_settings')}
          />
        </List>
      </Grid>
      <Typography className={classes.heading}>{i18n.t('resources')}</Typography>
      <Grid className={classes.itemWrapper}>
        <List>
          <AccountMenuItem
            isRouteGetHelp
            title={i18n.t('get_help')}
            icon={helpIcon}
            ariaLabel={i18n.t('open_get_help')}
          />
        </List>
        <List>
          <AccountMenuItem
            // path={ROUTES.TERMS}
            externalPath='https://chargelab.co/driver-and-site-host-terms'
            title={i18n.t('terms_of_service')}
            icon={termsIcon}
            ariaLabel={i18n.t('open_terms_of_service')}
          />
        </List>
      </Grid>
    </div>
  );
};

export default withStyles(settinStyles)(AccountMenu);

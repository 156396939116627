import Visa from '../../assets/icons/visa.svg';
import MasterCard from '../../assets/icons/mastercard.svg';
import AmericanExpress from '../../assets/icons/amex.svg';
import Discover from '../../assets/icons/discover.svg';
import Diners from '../../assets/icons/diners.svg';
import Jcb from '../../assets/icons/jcb.svg';
import UnoinPay from '../../assets/icons/unionpay.svg';

export const CrediCardIcon = ({ brand = '' }) => {
    switch (brand.toLowerCase()) {
        case 'visa': {
            return <img src={Visa} alt={`Visa`} style={{ width: '2.5rem' }} />;
        }

        case 'mastercard': {
            return (
                <img
                    src={MasterCard}
                    alt={`Vaster card`}
                    style={{ width: '2.5rem' }}
                />
            );
        }

        case 'amex': {
            return (
                <img
                    src={AmericanExpress}
                    alt={`American express`}
                    style={{ width: '2.5rem' }}
                />
            );
        }

        case 'discover': {
            return (
                <img
                    src={Discover}
                    alt={`Discover`}
                    style={{ width: '2.5rem' }}
                />
            );
        }

        case 'diners': {
            return (
                <img src={Diners} alt={`Diners`} style={{ width: '2.5rem' }} />
            );
        }

        case 'jcb': {
            return <img src={Jcb} alt={`Jcb`} style={{ width: '2.5rem' }} />;
        }

        case 'unionpay': {
            return (
                <img
                    src={UnoinPay}
                    alt={`Unoin pay`}
                    style={{ width: '2.5rem' }}
                />
            );
        }

        default: {
            return <></>;
        }
    }
};

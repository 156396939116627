import { Grid, Typography } from '@mui/material';
import { ClassNameMap, withStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { ItemStyles } from './styles/itemStyles';
import { Theme } from '../../../stores/types/theme.interface';
import { getTheme } from '../../../stores/selectors/theme.selector';

interface IProps {
  classes: ClassNameMap;
  Icon: any;
  details: string;
  label: string;
  ariaLabel?: string;
}

const SessionItem = ({ classes, Icon, details, label, ariaLabel }: IProps) => {
  const theme: Theme = useSelector(getTheme);
  return (
    <Grid>
      <Grid
        className={classes.imageWrapper}
        style={{ backgroundColor: theme.secondary || '#E8F7FC' }}
        aria-label={ariaLabel}
      >
        {Icon(theme.primary || '#117DB8')}
      </Grid>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.details}>
        {details ? details : '---'}
      </Typography>
    </Grid>
  );
};

export default withStyles(ItemStyles)(SessionItem);
